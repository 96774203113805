import React, { useCallback, useEffect, useMemo, useState } from "react";

import { getUser } from "api/user";
import { ID } from "types/api";
import { UserContext } from "types/context";

interface ProfileUpdaterHookInterface {
  id: ID;
}

const useProfileUpdater = (props: ProfileUpdaterHookInterface) => {
  const { id } = props;
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<UserContext>();

  const getUserProfile = useCallback(async () => {
    try {
      if (id != undefined) {
        const response = await getUser(id);
        setUser(response?.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
    }
  }, [id]);

  useEffect(() => {
    getUserProfile();
  }, []);

  return useMemo(
    () => ({
      user,
      loading,
    }),

    [user, loading]
  );
};

export default useProfileUpdater;
