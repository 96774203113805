import React from "react";

import useGlobalContext from "hooks/common/useGlobalContext";
import useProfileUpdater from "hooks/user/useProfileUpdater";
import Profile from "templates/user/profile";
import { IGlobalStateKey } from "types/context";

const UserProfileRedirect = () => {
  const { globalState } = useGlobalContext();

  const id = globalState[IGlobalStateKey.USER];

  const { user } = useProfileUpdater(id);

  return <Profile user={user} />;
};

export default UserProfileRedirect;
