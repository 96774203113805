import { ID, UserData } from 'types/api';

import axios from '../utils/axios';

const DEFAULT_ROUTE = '/users';

export interface UserResponse extends UserData {
}
 
export const getUser = async (id: ID) => {
  try {
    return await axios.get(`${DEFAULT_ROUTE}/${id}`);
  } catch(error) {
    console.error(error);
    throw error;
  }  finally {
    
  }
}