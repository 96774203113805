import React, { useCallback, useEffect, useState } from "react";

import { PollResponse, getFilteredPolls } from "api/poll";
import ClientNode from "components/ClientNode";
import Avatar from "components/Icons/Avatar";
import PollSection from "components/Polls/Section";
import PollThumbnail from "components/Polls/Thumbnail";
import SEO from "components/seo";
import useGlobalContext from "hooks/common/useGlobalContext";
import useLoginRedirect from "hooks/common/useLoginRedirect";
import { UserContext } from "types/context";
import { getCreatoUser } from "utils/localStorage";

interface IProfile {
  user: UserContext;
}

const Profile: React.FunctionComponent<IProfile> = ({ user }) => {
  const { globalState } = useGlobalContext();
  const [polls, setPolls] = useState<PollResponse[]>([]);

  const getPolls = useCallback(async () => {
    try {
      const response = await getFilteredPolls({
        field: "creator",
        where_opStr: "==",
        where_value: getCreatoUser()?.uid,
      });
  
      setPolls(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  useEffect(() => {
    getPolls();
  }, []);

  useLoginRedirect();

  return (
    <>
      <SEO title="Profile" />
      <ClientNode>
      <div className="flex">
      <div className="mr-4 flex-shrink-0">
        <Avatar uri={globalState.user.photoURL!}/>
      </div>
      <div className="w-full flex justify-start items-center gap-3">
        <h4 className="text-lg font-bold">{globalState.user.displayName}</h4>
        {/* <p className="mt-1">
          Repudiandae sint consequuntur vel. Amet ut nobis explicabo numquam expedita quia omnis voluptatem. Minus
          quidem ipsam quia iusto.
        </p> */}
      </div>
    </div>
        <PollSection
          title="DareMe"
        >
        {polls && polls?.map(({category, deadline, creator, ...item}) => 
          <PollThumbnail 
            key={item.id} 
            category={category} 
            deadline={new Date(deadline)} 
            user={creator}
            {...item}/>
        )}
      </PollSection>
    </ClientNode>
    </>
  );
};

export default Profile;
