import { useEffect, useMemo } from "react";

import { navigate } from "gatsby-link";
import { isLoggedIn } from "utils/auth";

import useLoading from "./useLoading";

const useLoginRedirect = () => {
  const { setLoading } = useLoading(true);
  const user = isLoggedIn();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/auth/login");
    }

    setLoading(false)
  }, [user, setLoading]);
  
  return useMemo(() => ({}), []);
};

export default useLoginRedirect;
